import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"
import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
    if (location.pathname === "/recruit/dummy_1/parttime") {
        navigate("/404", { replace: true }); 
    }
    if (location.pathname === "/recruit/dummy_2/parttime") {
        navigate("/404", { replace: true });
    }
    if (location.pathname === "/recruit/eyelist/fulltime") {
        navigate("/404", { replace: true });
    }
    if (location.pathname === "/recruit/eyelist/other") {
        navigate("/404", { replace: true });
    }
    if (location.pathname === "/recruit/eyelist/parttime") {
        navigate("/404", { replace: true });
    }
    if (location.pathname === "/recruit/stylist/other") {
        navigate("/404", { replace: true });
    }
};